import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FacilityAccountService {
    store(data) {
        promise =  api.client.post(config.end_point.facility.account.store, data);
        return promise;
    }

    update(id,data) {
        promise =  api.client.put(config.end_point.facility.account.update + '/' + id, data);
        return promise;
    }

    delete(id) {
        promise = api.client.delete(config.end_point.facility.account.delete + '/' + id);
        return promise;
    }

    async list(data) {
        promise = await api.client.get(config.end_point.facility.account.list, {params: data});
        return promise;
    }
}

export const facilityAccountService = new FacilityAccountService();
