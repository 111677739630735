import AccountFormModalComponent from "./account-form-modal.component";
import { facilityAccountService } from "@/services/facility/account/account.service";
import { alertService } from "@/services/alert/alert.service";
export default {
    components: {
        AccountFormModalComponent
    },
    data() {
        return {
            data: [],
            dataReady: false,
            auth: this.$storage.get("auth")
        }
    },
    async mounted() {
        this.list()
    },
    methods: {

        async list() {
            this.dataReady = false
            alertService.loading();
            await facilityAccountService.list({
                paginate : 1,
                per_page: 10
            }).then((response) => {
                this.data = response.data.data
                this.dataReady = true
                alertService.close();
            }).catch((error) => {
                alertService.close();
                console.log(error)
            });

        },

        async reList(type) {
            await this.list()
            alertService.success(
                this.$t('facility.global.success'),
                this.$t(`facility.account.subaccount-${type == 'new' ? 'created' : 'updated'}-success`)
            )
        },

        async delete$(id) {
            var callback = async () => {
                await facilityAccountService.delete(id)
                await this.list()
                alertService.success(
                    this.$t('facility.global.success'),
                    this.$t('facility.account.subaccount-deleted-success')
                );
            }
            alertService.confirmation(callback, this.$t('facility.account.are-you-sure'), this.$t('facility.account.cant-be-revert'));
        },

        showModal(data) {
            this.$refs.accountFormModal.showModal(data)
        },
    }

}