<template>
  <b-modal
    id="account-form-modal"
    centered
    hide-footer
    :title="$t('facility.account.account-information')"
    size="lg"
  >
    <div class="p-2">
      <div class="form-group" v-if="error">
        <div class="alert alert-danger" role="alert">
          <template v-if="error && error.errors.email">
            <div v-for="x in error.errors.email">
              {{ x }}
            </div>
          </template>
          <template v-if="error && error.errors.name">
            <div v-for="x in error.errors.name">
              {{ x }}
            </div>
          </template>
          <template v-if="error && error.errors.password">
            <div v-for="x in error.errors.password">
              {{ x }}
            </div>
          </template>
          <div v-else>{{ error.message }}</div>
        </div>
      </div>
      <form @submit.prevent="user_id ? update$() : store$()" class="container" autocomplete="off">
        <div class="form-group row">
          <label class="col-3">{{ $t("facility.account.name") }}</label>
          <input
            class="form-control col-9"
            v-model="form.name"
            type="text"
            required
          />
        </div>
        <div class="form-group row">
          <label class="col-3">{{ $t("facility.account.email") }}</label>
          <input
            :disabled="user_id"
            class="form-control col-9"
            v-model="form.email"
            type="email"
            required
          />
        </div>
        <template v-if="!user_id">
          <div class="form-group row">
            <label class="col-3">{{ $t("facility.account.password") }}</label>
            <input
              class="form-control col-9"
              v-model="form.password"
              type="password"
              required
            />
          </div>
          <div class="form-group row">
            <label class="col-3">{{
              $t("facility.account.password-confirm")
            }}</label>
            <input
              class="form-control col-9"
              v-model="form.password_confirmation"
              type="password"
              required
            />
          </div>
        </template>
        <div class="form-group row">
          <label class="col-12">{{ $t("facility.account.delegation") }}</label>
          <div class="offset-3 col-9">
            <div class="d-flex justify-content-between">
              <label>{{ $t("facility.account.control-account") }}</label>
              <b-form-radio-group v-model="form.control_account" required>
                <b-form-radio :value="1">{{
                  $t("facility.account.yes")
                }}</b-form-radio>
                <b-form-radio :value="0">{{
                  $t("facility.account.no")
                }}</b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
          <div class="offset-3 col-9">
            <div class="d-flex justify-content-between">
              <label>{{ $t("facility.account.create-parking-edit-inventory") }}</label>
              <b-form-radio-group v-model="form.create_edit_inventory" required>
                <b-form-radio :value="1">{{
                  $t("facility.account.yes")
                }}</b-form-radio>
                <b-form-radio :value="0">{{
                  $t("facility.account.no")
                }}</b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
          <div class="offset-3 col-9">
            <div class="d-flex justify-content-between">
              <label>{{ $t("facility.account.edit-inventory-only") }}</label>
              <b-form-radio-group v-model="form.change_inventory" required>
                <b-form-radio :value="1">{{
                  $t("facility.account.yes")
                }}</b-form-radio>
                <b-form-radio :value="0">{{
                  $t("facility.account.no")
                }}</b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
          <div class="offset-3 col-9">
            <div class="d-flex justify-content-between">
              <label>{{ $t("facility.account.edit-fee") }}</label>
              <b-form-radio-group v-model="form.edit_fee" required>
                <b-form-radio :value="1">{{
                  $t("facility.account.yes")
                }}</b-form-radio>
                <b-form-radio :value="0">{{
                  $t("facility.account.no")
                }}</b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
          <div class="offset-3 col-9">
            <div class="d-flex justify-content-between">
              <label>{{ $t("facility.account.edit-booking") }}</label>
              <b-form-radio-group v-model="form.edit_booking" required>
                <b-form-radio :value="1">{{
                  $t("facility.account.yes")
                }}</b-form-radio>
                <b-form-radio :value="0">{{
                  $t("facility.account.no")
                }}</b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
        <div class="text-center mt-3">
          <b-button variant="info" class="mx-1" type="submit">
            {{ $t("facility.global.confirm") }}
          </b-button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { facilityAccountService } from "@/services/facility/account/account.service";
import { alertService } from "@/services/alert/alert.service";
export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        control_account: 0,
        create_edit_inventory: 0,
        change_inventory: 0,
        edit_fee: 0,
        edit_booking: 0,
        lang: this.$i18n.locale
      },
      user_id: null,
      error: null,
    };
  },
  async mounted() {},
  methods: {
    async store$() {
      this.error = null;
      await alertService.loading();
      await facilityAccountService
        .store(this.form)
        .then((response) => {
          this.$bvModal.hide("account-form-modal");
          this.$emit("success-handler", "new");
        })
        .catch((error) => {
          this.error = error;
          alertService.close();
        });
    },

    async update$() {
      this.error = null;
      await facilityAccountService
        .update(this.user_id, this.form)
        .then(async () => {
          this.$bvModal.hide("account-form-modal");
          this.$emit("success-handler", "update");
        })
        .catch((error) => {
          this.error = error.message;
        });
    },

    showModal(data) {
      this.clearForm();
      this.$bvModal.show("account-form-modal");
      this.error = null;
      this.user_id = null;
      if (data) {
        this.user_id = data.user_id;
        this.form = {
          name: data.admin_name,
          email: data.user.email,
          control_account: data.control_account,
          create_edit_inventory: data.create_edit_inventory,
          change_inventory: data.change_inventory,
          edit_fee: data.edit_fee,
          edit_booking: data.edit_booking,
          auth_type: "airport",
          lang: this.$i18n.locale
        };
      }
    },

    clearForm() {
      this.form = {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        control_account: 0,
        create_edit_inventory: 0,
        change_inventory: 0,
        edit_fee: 0,
        edit_booking: 0,
        lang: this.$i18n.locale
      };
    },
  },
};
</script>

<style lang="scss"></style>
